import api from './api';

export async function getMarketReports(symbol, params) {
    const response = await api.request({
        method: 'get',
        url: `/market-report/reports/${symbol}`,
        params: {
            // pageSize: 1,
            ...params
        },
        isOTC: true
    });
    return response.data;
};

export async function emailMarketReports(data) {
    const response = await api.request({
        method: 'post',
        url: `/market-report/email/share`,
        data,
        isOTC: true
    });
    return response.data;
};
