import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/Auth.context';
import { ThemeProvider } from './context/Theme.context';
import Layout from './components/Layout';
import MarketReports from './container/MarketReports';
import { ROUTES } from './constants/routes';

const App = () => {
  return (
    <div className='otc-theme'>
      <ThemeProvider>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path={ROUTES.marketReport} element={<MarketReports />} />
            </Routes>
          </Layout>
        </AuthProvider>
      </ThemeProvider>
    </div >
  );
};

export default App;
