import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { emailMarketReports } from '../../api/marketReports';
import { TextArea, TextBox } from 'devextreme-react';
import { Button, TextLink } from '@otcmarketsgroup/otcm-ui';
import CharacterCount from '../../components/CharacterCount';
import FieldLabel from '../../components/FieldLabel';
import Footnote from '../../components/Footnote';
import Label from '../../components/Label';
import styles from './ShareReport.module.scss';
import FieldError from '../../components/FieldError/FieldError';
import { createArrayList } from '../../utlis/helper';
import { isEmail } from '../../utlis/validations';

const nameLabel = { 'aria-label': 'Name' };
const messageLabel = { 'aria-label': 'Message' };
const MAX_LENGTH = 1500;

const ShareReport = ({ className, report, onCancel }) => {
    const [emailValue, setEmailValue] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [isEmailFocus, setEmailFocus] = useState(false);
    const [messageValue, setMessageValue] = useState(null);
    const [successShare, setSuccessShare] = useState(false);
    const [shareError, setShareError] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const chatacterCount = messageValue?.length || 0;

    useEffect(() => {
        return () => {
            setIsSending(false);
            setEmailError(false);
            setEmailFocus(false);
            setSuccessShare(false);
            setShareError(false);
            setEmailValue('');
            setMessageValue('');
        };
    }, [report]);

    const valueChanged = useCallback((data) => {
        const { name, value } = data.component.option();

        switch (name) {
            case 'email':
                setEmailError(false);
                setEmailValue(value);
                break;
            case 'message':
                setMessageValue(value);
                break;
            default:
                break;
        };
    }, []);

    const handleFocusOut = (data) => {
        const { name, value } = data.component.option();
        const trimmedValue = value.trim();

        if (name === 'email' && trimmedValue === '') {
            setEmailValue('');
        }

        setEmailFocus(false);
    };

    const handleSendClick = _ => {
        let isValid = true;

        setShareError(false);

        if (!emailValue) {
            setEmailError('Incomplete Data Field. Please enter information to resolve.');
            return;
        }

        const emailList = createArrayList(emailValue);

        if (!emailList || emailList.length === 0) isValid = false;

        emailList.forEach(email => {
            const isEmailValid = isEmail(email);
            if (!isEmailValid) isValid = false;
        });

        if (!isValid) {
            setEmailError('One (or more) email addresses are invalid.');
            return;
        };

        const data = {
            emailTo: emailList,
            reportId: report.reportId
        };

        if (messageValue) data.text = messageValue;

        setIsSending(true);

        emailMarketReports(data)
            .then(_ => {
                setSuccessShare(true);
                setIsSending(false);
            })
            .catch(error => {
                setShareError(error?.response?.data?.error || 'Error sharing Market Report.');
                setIsSending(false);
            });
    };

    return successShare ? <div className={`${styles.container} ${className}`}>
        <div className={styles.confrmMsg}>
            <FontAwesomeIcon className='mrSm' icon={['far', 'check-circle']} />
            Market Report was sent to the list of email(s) provided.
        </div>
        <div className={styles.controls}>
            <Button title='OK' onClick={onCancel} />
        </div>
    </div> : <div className={`${styles.container} ${className}`}>
        <div>
            <FieldLabel text='Recipient Email(s)' />
            {emailError && <FieldError isFocus={isEmailFocus} errorMsg={emailError} />}
            <TextBox
                className={cn({
                    [styles.incomplete]: emailError
                })}
                name='email'
                placeholder='email@email.com'
                inputAttr={nameLabel}
                valueChangeEvent='keyup'
                value={emailValue}
                onFocusIn={() => setEmailFocus(true)}
                onFocusOut={handleFocusOut}
                onValueChanged={valueChanged}
            />
            <Footnote text='Separate multiple addresses with a comma.' />
        </div>
        <div className='mtMed'>
            <FieldLabel text='Add a Personal Message' />
            <TextArea
                name='message'
                height={100}
                maxLength={MAX_LENGTH}
                placeholder='Enter Personal Message'
                inputAttr={messageLabel}
                valueChangeEvent='keyup'
                value={messageValue}
                onValueChanged={valueChanged}
            />
            <CharacterCount inputLength={chatacterCount} total={MAX_LENGTH} />
        </div>
        {shareError && <Label className='mtLg' isError>
            <b>{shareError}</b>
        </Label>}
        <div className={styles.controls}>
            <TextLink text='Cancel' onClick={onCancel} />
            <Button className='mlLg' title='Send' inactive={isSending} onClick={handleSendClick} />
        </div>
    </div>;
};

ShareReport.propTypes = {
    className: PropTypes.string,
    report: PropTypes.object,
    onCancel: PropTypes.func
};

export default ShareReport;
