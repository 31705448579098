import React from 'react';
import PropTypes from 'prop-types';
import styles from './TableFooter.module.scss';

const TableFooter = ({ children }) => {
  return <div className={styles.component}>
    {children}
  </div>;
};

TableFooter.propTypes = {
  children: PropTypes.node
};

export default TableFooter;
