import api from './api';

export const getEmailList = async symbol => {
  const response = await api.request({
    method: 'get',
    url: `/user/market-report/email-list/${symbol}`
  });
  return response.data;
};

