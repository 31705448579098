import queryString from 'query-string';

export const getQueryObj = search => {
    return queryString.parse(search);
};

export const redirectIQ = _ => window.location.href = 'https://www.otciq.com/home';

export const isWeekend = date => {
    const createDate = new Date(date);
    const dayOfWeek = createDate.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
};

export const disableDates = args => {
    const dayOfWeek = args.date.getDay();
    const isWeekend = args.view === 'month' && (dayOfWeek === 0 || dayOfWeek === 6);

    return isWeekend;
};

export const setSessionStorage = (key, value) => {
    return new Promise((resolve, reject) => {
        try {
            sessionStorage.setItem(key, value);
            resolve('Successfully set ' + key);
        } catch (error) {
            reject('Error setting ' + key + ': ' + error.message);
        }
    });
};

export const createID = array => {
    const id = Math.floor(Math.random() * 90000) + 10000;
    if (array && array.find(item => item.id === id)) {
        createID(array);
    } else {
        return id;
    }
};

export const createArrayList = str => {
    if (str.includes(',') || str.includes(';')) {
        return str.split(/[;,]/).map(word => word.trim()).filter(word => word !== '');
    } else {
        return [str];
    }
};
