import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outline } from '@otcmarketsgroup/otcm-ui';
import styles from './Accordion.module.scss';

const Accordion = ({ accent, children, title }) => {
    const [isOpen, setIsOpen] = useState(null);
    const contentHeight = useRef();

    const toggleOpen = _ => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper} >
                <Outline mode='heading' accent={accent}>
                    {title}
                </Outline>
                <div onClick={toggleOpen}>
                    <FontAwesomeIcon className={cn(styles.arrow, {
                        [styles.active]: isOpen
                    })} icon={['fas', 'chevron-down']} />
                </div>

                <div ref={contentHeight} className={styles.innerContainer} style={
                    isOpen
                        ? { height: contentHeight.current.scrollHeight }
                        : { height: "0px" }
                }>
                    <div className={styles.innerContainer}>{children}</div>
                </div>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    accent: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string
};

export default Accordion;