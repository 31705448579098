import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import getConfig from './config';
import './icons';
import 'normalize.css/normalize.css';
import './styles/global.scss';
import './styles/dx.common.scss';
import './styles/dx.light.scss';
import './styles/otc-theme.scss';
const { GTM_ID } = getConfig();

let root;

const tagManagerArgs = {
  gtmId: GTM_ID
};

TagManager.initialize(tagManagerArgs);

const component = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// load OTC fonts
WebFont.load({
  google: {
    families: [
      'Nunito Sans:200,200i,400,400i,600,600i,700,700i,800,800i900,900i',
      'Source Serif Pro:400,700'
    ]
  }
});

const version = process.env.REACT_APP_VERSION;
const el = document.querySelector('html');
el.setAttribute('data-ui-version', version);

const rootElement = document.getElementById('root');
root = ReactDOM.createRoot(rootElement);
root.render(component);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
