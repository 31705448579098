import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'devextreme-react/popup';
import { ThemeContext } from '../../context/Theme.context';
import { getMarketReports } from '../../api/marketReports';
import { getEmailList } from '../../api/user';
import { storage } from '../../constants';
import { getSecurities } from '../../api/company';
import ShareReport from '../../container/ShareReport';
import CompanyHeader from '../CompanyHeader';
import ErrorPage from '../ErrorPage';
import { withRouter } from '../../components/WithRouter';
import Loading from '../../components/Loading';
import MarketReportTable from '../../components/MarketReportTable';
import EmailList from '../../components/EmailList';
import DisplayResults from '../../components/DisplayResults';
import More from '../../components/More';
import TableFooter from '../../components/TableFooter';
import SecuritiesList from '../../components/SecuritiesList';
import styles from './MarketReports.module.scss';
import { format } from '../../utlis/locale';

const ASC = 'ASC';
const DESC = 'DESC';

const MarketReports = ({ params }) => {
    const [themeState] = useContext(ThemeContext);
    const [marketReportData, setMarketReportData] = useState(null);
    const [marketReportDataSet, setMarketReportDataSet] = useState(null);
    const [marketReportParams, setMarketReportParams] = useState(null);
    const [reloading, setReloading] = useState(false);
    const [currentSecurity, setCurrentSecurity] = useState('');
    const [securities, setSecurities] = useState(null);
    const [marketReportError, setMarketReportError] = useState('');
    const [showErrorPage, setErrorPage] = useState(false);
    const [sortDir, setSortDir] = useState(DESC);
    const [popupVisible, setPopup] = useState(false);
    const [shareReport, setShareReport] = useState(null);
    const [emailList, setEmailList] = useState(null);
    const [emailListError, setEmailListError] = useState(false);
    const symbol = params?.symbol;
    const marketReportsLoaded = !!marketReportData && themeState.isReady;
    const accentColor = themeState.color;
    const shareTitle = `OTC Market Report - ${shareReport?.symbol} ${format(shareReport?.date, '', 'staticDate')}`;
    const hasAdditionalSecurities = securities && securities.length > 0;
    const totalResults = marketReportData?.totalResults;
    const hasMorePages = marketReportData?.totalPages > marketReportData?.currentPage;

    useEffect(() => {
        setEmailListError(false);

        window.sessionStorage.setItem(storage.SYMBOL, symbol);

        setMarketReportParams({});
        setSortDir(DESC);

        getEmailList(symbol)
            .then(data => {
                setEmailList(data);
            })
            .catch(error => {
                console.error(`Failed Securities call: `, error);
                setEmailListError(true);
            });

        getSecurities()
            .then(data => {
                const currentSecurity = data.find(item => item.symbol.toLowerCase() === symbol.toLowerCase());
                const otherSecurities = data.filter(item => item.symbol.toLowerCase() !== symbol.toLowerCase());
                setCurrentSecurity(currentSecurity.company?.name);
                setSecurities(otherSecurities);
            })
            .catch(error => console.error(`Failed Securities call: `, error));

    }, [symbol]);

    useEffect(() => {
        if (marketReportParams !== undefined && marketReportParams !== null) loadMarketReports();
    }, [marketReportParams]);

    const loadMarketReports = _ => {
        if (marketReportsLoaded) setReloading(true);

        getMarketReports(symbol, marketReportParams)
            .then(data => {
                const newData = data.results;
                const isPageCall = marketReportParams?.page;

                setReloading(false);
                setMarketReportData(data);
                setMarketReportDataSet(prevDataSet => {
                    return isPageCall ? [...prevDataSet, ...newData] : newData;
                });
            })
            .catch(error => {
                if (error?.response?.status === 403) {
                    setReloading(false);
                    setErrorPage(true);
                    return;
                }

                setReloading(false);
                setMarketReportError(error?.response?.data?.error || 'Error fetching Market Reports.');
            });
    };

    const handleHeaderClick = _ => {
        const sort = sortDir === DESC ? ASC : DESC;
        setSortDir(sort);
        setMarketReportParams({ sortOn: sort });
    };

    const handleShareClick = report => {
        setShareReport(report);
        setPopup(true);
    };

    const handlePageClick = _ => {
        setMarketReportParams(prevData => {
            return { ...prevData, page: marketReportData.currentPage + 1 };
        });
    };

    const handleShareClose = _ => {
        setPopup(false);
        setShareReport(null);
    };

    return showErrorPage ? <ErrorPage /> : <div className={styles.container}>
        <CompanyHeader className='mbXL' symbol={symbol} />
        <Loading loaded={marketReportsLoaded} reloading={reloading} error={marketReportError}>
            <div className={styles.dataContainer}>
                <div className={styles.main}>
                    <div>
                        <MarketReportTable
                            data={marketReportDataSet}
                            symbol={symbol}
                            accent={accentColor}
                            params={marketReportParams}
                            onHeaderClick={handleHeaderClick}
                            onShareClick={handleShareClick} />
                        {marketReportData && <TableFooter>
                            <More onClick={handlePageClick} disabled={!hasMorePages} />
                            <DisplayResults show={marketReportDataSet?.length} total={totalResults} text={'Market Reports'} />
                        </TableFooter>}
                    </div>
                </div>
                <div className={styles.side}>
                    <EmailList className='mbXL' accent={accentColor} lists={emailList} error={emailListError} />
                    {hasAdditionalSecurities && <SecuritiesList className='mbXL' securityName={currentSecurity} securities={securities} accent={accentColor} />}
                </div>
            </div>
        </Loading>
        <Popup
            visible={popupVisible}
            dragEnabled={false}
            title={shareTitle}
            hideOnOutsideClick
            showCloseButton
            position='center'
            width={600}
            height={'auto'}
            onHidden={handleShareClose}
        >
            <ShareReport report={shareReport} onCancel={handleShareClose} />
        </Popup>
    </div>;
};

MarketReports.propTypes = {
    params: PropTypes.object,
};

export default withRouter(MarketReports);