import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from "prop-types";
import Accordion from '../Accordion';
import styles from './EmailList.module.scss';

const EmailList = ({ className, accent, lists }) => {
    const [isOpen, setIsOpen] = useState(false);
    const users = lists?.otciqUsers;
    const additionalRecipients = lists?.additionalRecipients;
    const hasUsers = !!(users && users.length > 0);
    const hasAddtRecpt = !!(additionalRecipients && additionalRecipients.length > 0);

    return (
        <div className={cn(styles.container, styles.toggleContainer, className, {
            [styles.open]: isOpen
        })}>
            <div className={cn(styles.toggleContainer, {
                [styles.open]: isOpen
            })}>
                <Accordion accent={accent} title='Email Distribution List' isActive={isOpen}>
                    <div className={styles.inside}>
                        {hasUsers && <div>
                            <h4 className={styles.title}>
                                OTCIQ Users
                            </h4>
                            {users.map(user => <div key={user.email} className='mbSm'>{user.name} {`(${user.email})`}</div>)}
                        </div>}
                        {hasAddtRecpt && <div className='mtMed'>
                            <h4 className={styles.title}>
                            Additional Recipients
                            </h4>
                            {additionalRecipients.map(user => <div className='mbSm'>{user.email}</div>)}
                        </div>}
                        <p className='mtMed'>
                            <a href='mailto:issuers@otcmarkets.com'>Contact Issuer Services</a> to make changes to Distribution List.
                        </p>
                    </div>
                </Accordion>
            </div>
        </div>
    );
};

EmailList.defaultProps = {
    accent: 'green'
};

EmailList.propTypes = {
    className: PropTypes.string,
    accent: PropTypes.string,
    lists: PropTypes.object
};

export default EmailList;
