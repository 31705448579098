import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import DataGrid, {
  Column
} from 'devextreme-react/data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outline } from '@otcmarketsgroup/otcm-ui';
import styles from './MarketReportTable.module.scss';

const MarketReportTable = ({ accent, data, symbol, onHeaderClick, onShareClick }) => {
  const dataGridRef = useRef(null);
  const [dataSet, setDataSet] = useState(null);

  const handleCellClick = e => {
    if (e?.rowType === 'header' && e?.column?.dataField === 'date') {
      onHeaderClick();
    }
  };

  const sortStringsConsideringCulture = _ => {
    return;
  };

  const marketReportColumns = [
    {
      dataField: 'date', caption: 'Week Ending', dataType: 'date', format: 'MM/dd/yyyy',
      sortingMethod: sortStringsConsideringCulture
    },
    { dataField: 'symbol', allowSorting: false, },
    {
      caption: 'Report',
      allowSorting: false,
      cellRender: item => <a className={styles.link} href={`/market-report/report/pdf/${symbol}?reportId=${item?.data?.reportId}&date=${item?.data?.date}`} target='_blank' rel='noopener noreferrer'>
        <FontAwesomeIcon icon={['far', 'file-pdf']} />
        <div>View</div>
      </a>
    },
    {
      caption: '', cellRender: item => <div className={styles.link} onClick={_ => onShareClick(item?.data)}>
        <FontAwesomeIcon icon={['fas', 'share-from-square']} />
        <div>Share Market Report</div>
      </div>
    }
  ];

  useEffect(() => {
    setDataSet([...data]);
  }, [data]);
  
  useEffect(() => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearSorting();
    }
  }, [symbol]);

  const handlePreparing = e => {
    console.log(e);
    if (e.target === 'header') {
      e.target.visible = false;
    } 
  };

  return <div className={styles.container}>
    <Outline mode='heading' accent={accent}>
      OTC Market Report
    </Outline>
    <DataGrid
      onContextMenuPreparing={handlePreparing}
      ref={dataGridRef}
      dataSource={dataSet}
      paging={{ enabled: false }}
      onCellClick={handleCellClick}
      loadPanel={{ enabled: false }}
      noDataText='Not available'
    >
      {marketReportColumns.map((column, i) => (
        <Column key={`column${i}`} {...column} />
      ))}
    </DataGrid>
  </div>;
};

MarketReportTable.defaultProps = {
  accent: 'green',
};

MarketReportTable.propTypes = {
  accent: PropTypes.string,
  data: PropTypes.array,
  asOfDate: PropTypes.string,
  symbol: PropTypes.string,
  params: PropTypes.object,
  onHeaderClick: PropTypes.func,
  onShareClick: PropTypes.func
};

export default MarketReportTable;
